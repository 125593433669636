/* Global CSS */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Volkhov&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

html {
  scroll-behavior: smooth;
}

.font-montserrat {
  font-family: 'Montserrat', sans-serif;
}

.active {
  opacity: 1;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: whitesmoke;
}

.App-link {
  color: #38B2AC;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.author {
  font-size: 1em; /* 14px equivalent */
  line-height: 20px;
  font-family: 'Montserrat', 'bold';
  font-weight: 400;
  text-transform: uppercase;
}

.block {
  padding-left: 1em;
  padding-right: 1em;
  padding-top: .5em;
  padding-bottom: .5em;
  border: 1px solid;
  background-color: black;
  border-radius: 10px;
  transition: all 0.5s ease-out;
  align-items: center;
  justify-content: center;
}

.block:hover {
  color: #333333;
  background: linear-gradient(45deg, #00F6FF, #B3FFAA);
  transform: scale(1.05);
  border: 0px;
  border-color: transparent;
  transition: background 1s ease-in-out; /* Add transition for smooth color change */
}

.brand-block {
  max-height: 75px;
}

.contact-button {
  color: #38B2AC;
  font-family: Montserrat;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 20px;  
  margin-right: 1.5em; /* adjust as needed */
  border: 2px solid transparent; /* add a transparent border */
  transition: border-color 0.3s ease-in-out; /* animate border color, not border */
}

.contact-button:hover {
  border-color: #38B2AC; /* change border color on hover */
  font-family: Montserrat;
  border-radius: 20px;
}

.contact-form {
  display: grid;
  grid-template-columns: 1fr;
  gap: .5rem;
  width: 100%
}

.contact-container {
  background-color: #111111;
  color: #FFF;
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-content {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-info {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.contact-info div {
  display: flex;
  flex-direction: column;
}

.contact-info h2 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.contact-subtext {
  width: 60VW;
  font-size: 24px;
  margin-bottom: 2rem;
  font-size: 1rem;
  line-height: 1.5;
  color: whitesmoke;
  text-align: center; /* Horizontally center the text */
}

.cta-button {
  color: whitesmoke;
  background-color: black;
  margin-bottom: 1em;
  width: auto;
  height: auto;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  flex-grow: 1; /* Add this line */
  transition: all 0.5s ease-out;
}

.cta-button:hover {
  color: #333333;
  background: linear-gradient(45deg, #00F6FF, #B3FFAA);
  transform: scale(1.05);
  border-color: transparent;
  transition: background 1s ease-in-out; /* Add transition for smooth color change */
}

.cta-button-alt {
  color: black;
  transition: transform 0.3s ease-in-out;
}

.cta-button-alt:hover {
  transform: scale(1.05);
  background: transparent;
  transition: background 1s ease-in-out; /* Add transition for smooth color change */
}

.cta-container {
  display: flex;
  justify-content: space-between; /* Optional: Adds space between the buttons */
  flex-wrap: wrap;
  width: 250px;
  margin-top: auto;
}

/* If you're not using Tailwind CSS, replace the rgba color with your desired color */
.custom-focus:focus {
  outline: none;
  border: 1px solid #38B2AC;
}

input:focus {
  outline: none;
}

.form-input{
  width: 100%;
  margin-bottom: 1rem;
  background: black;
}

.form-textarea,
.submit-button {
  width: 100%;
  margin-bottom: 1rem;
}

.gradient {
  background: linear-gradient(45deg, #00F6FF, #B3FFAA);
}

.header {
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat';
}

/* Hero and CTA Sections */
.hero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  margin: 0 auto;
  z-index: 10;
}

.html {
  scroll-behavior: smooth;
}

.logos-grid {
  display: grid;
  gap: 30px;
  justify-items: center;
  width: 60vw;
  margin: 0 auto;
}

.navbar {
  max-height: 70vh;
  padding-top: 1em;
  padding-bottom: 1em;
}

.parent-container {
  background: #f8f8f8; /* Example background color */
  color: #333; /* Font color */
  padding: 15px; /* Default padding for mobile */
  justify-content: flex-start;
}

.profile-picture {
  height: 70px;
  width: 70px;
}

.project-logo {
  max-height: 28px;
  width: auto;
  padding: 2px;
}

.projects {
  max-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.projects-container {
  width: auto;
  height: auto;
  padding: 50px;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns for mobile */
  grid-gap: 15px; /* Space between logos */
  justify-items: center; /* Center logos horizontally */
  align-items: center; /* Center logos vertically */
}

.projects-item {
  max-width: 100%; /* Ensure logos do not overflow their containers */
  padding: 10px; /* Padding around logos */
}

.quote {
  font-size: 1em; /* 16px equivalent */
  line-height: 33px;
  font-family: 'Volkhov';
  font-weight: 100;
  max-width: 100%; /* Full width on mobile */
}

.rotating-text {
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  font-family: 'Volkhov';
  font-weight: 400;
}

.rotating-text.visible {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.section-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 80vw; /* Maximum width for mobile */
  margin: 0 auto; /* Center align */
}

.section-header {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-size: 24px; /* Header font size for mobile */
  line-height: 1.2;
  margin-bottom: 1em;
  margin-top: 1em;
  display: flex;
  justify-content: center;
}

.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.services-content {
  margin-bottom: 30px; /* Space between services */
  width: 100%;
}

.service-icon-gradient {
  background: linear-gradient(45deg, #00F6FF, #B3FFAA);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; /* Hide the original text color */
}

.submit-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #38B2AC;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #38B2AC;
}

.testimonials {
  min-height: 80vh;
  display: flex;
  align-items: center; /* Aligns items to the top of the container */
  justify-content: flex-start;
}

.website {
  font-family: 'Montserrat';
  color: #38B2AC;
  font-size: 0.9em;
  font-weight: bold; /* This line makes the text bold */
}

/* Media Queries for min-width: 768px */
@media (min-width: 768px) {
  .section-body {
    max-width: 65vw;
  }
  .services-content {
    width: calc(50% - 10px);
  }
  h1, h2 {
    font-size: 44px;
    line-height: 55px;
  }
  .logos-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .cta-container{
    width: 400px;
    margin-top: 1em;
  }
  .cta-button {
    width: 400px;
    margin-top: 1em;
  }
  .projects-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .quote {
    font-size: 1.5em;
    line-height: 33px;
    max-width: 80%;
  }
  .section-header {
    font-size: 28px;
  }

  .contact-form {
    grid-template-columns: repeat(2, 1fr); /* Two columns for larger screens */
  }

  .form-input {
    width: auto;
  }

  .form-textarea,
  .submit-button {
    grid-column: span 2; /* Make these elements span full width */
  }
}

/* Media Queries for min-width: 1024px */
@media (min-width: 1024px) {
  .section-body {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 2em;
  }
  .services-content {
    width: calc(50% - 10px);
  }
  .contact-content {
    max-width: 80vw; /* Increase max width for medium screens */
  }
  .contact-info {
    flex-direction: column; /* Stack the contact info for smaller screens */
    align-items: center; /* Center the items */
  }
  .contact-info div {
    margin-bottom: 1rem;
  }

  .cta-button {
    width: calc(50% - 10px); /* Adjust as needed. The "10px" accounts for any desired space between the buttons. */
  }

  .cta-button {
    width: calc(50% - 10px); /* Adjust as needed. The "10px" accounts for any desired space between the buttons. */
    margin-right: 20px; /* Add a right margin to create a gap between the buttons */
  }

  .cta-button:last-child {
    margin-right: 0; /* Remove the right margin from the last button to prevent extra space on the right */
  }

  .projects-grid {
    grid-template-columns: repeat(5, 1fr); /* 5 columns for larger screens */
    grid-gap: 20px; /* Larger gap between logos */
  }
  .quote {
    font-size: 1.625em; /* 26px equivalent */
    line-height: 38px;
    max-width: 60vw; /* Adjust width as needed */
  }
  .section-header {
    font-size: 28px; /* Header font size for laptop */
  }
  .testimonials {
    height: 60vh;
  }
}

/* Media Queries for min-width: 1440px */
@media (min-width: 1440px) {
  .parent-container {
    padding: 2em; /* Increased padding for 4K screens */
  }
  .hero-container {
    align-items: center;
  }
  .cta-container {
    width: 500px;
  }
  .contact-content {
    max-width: 50vw; /* Increase max width for medium screens */
  }
  .projects-grid {
    grid-gap: 20px; /* Adjust gap size for visual appeal on large screens */
  }
  .quote {
    font-size: 1.625em; /* 26px equivalent */
    line-height: 42px;
    max-width: 60vw; /* Adjust width as needed */
  }
  .section-header {
    font-size: 24px; /* Header font size for 4K screens */
  }
  .testimonials {
    height: 60vh;
  }
}


.loading-screen {
  width: 100px;
  height: 100px;
  background-color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  /* Ensure initial centering */
  transform: translate(-50%, -50%) scale(1);
  border-radius: 50%;
  animation: loadingAnimation 3s ease-in-out infinite, moveAround 5s linear infinite;
}

@keyframes loadingAnimation {
  0%, 100% {
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(1);
  }
  25% {
    border-radius: 60% 40% 60% 40% / 40% 60% 40% 60%;
    transform: translate(-50%, -50%) scale(0.8);
  }
  50% {
    border-radius: 40% 60% 30% 70% / 60% 40% 60% 40%;
    transform: translate(-50%, -50%) scale(1.2);
  }
  75% {
    border-radius: 30% 70% 50% 50% / 50% 30% 70% 50%;
    transform: translate(-50%, -50%) scale(0.9);
  }
}

@keyframes moveAround {
  0%, 100% {
    top: 50%;
    left: 50%;
  }
  25% {
    top: 45%;
    left: 55%;
  }
  50% {
    top: 55%;
    left: 45%;
  }
  75% {
    top: 50%;
    left: 60%;
  }
}

.places-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* This centers the cards horizontally */
  padding: 20px;
}

/* Responsive design for PlaceCard */
@media (max-width: 768px) { /* Adjustments for tablets and below */
  .place-card {
    width: calc(100% - 20px); 
    margin: 10px auto; /* Ensures margin around the card and centers it */
  }
}

@media (max-width: 480px) { /* Adjustments for mobile phones */
  .place-card {
    width: calc(100% - 20px); 
  }
}

/* New styles for place cards */
.place-card {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: left;
  justify-content: center;
  width: auto; /* Adjust based on layout */
  padding-bottom: 10px;
  margin: 10px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  overflow: hidden; /* Ensures images do not overflow the rounded corners */
}

/* Fixed aspect ratio for image containers */
.property-image-container {
  width: calc(100% - 20px); /* Subtracting total horizontal margin */
  height: 0; /* Height is controlled by padding-bottom */
  padding-bottom: 56.25%; /* Aspect ratio of 16:9 */
  position: relative; /* Needed for absolute positioning of the image */
  margin: 10px; /* Uniform margin on all sides */
}

.property-image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover the container without distortion */
}

.place-card img {
  height: 200px; /* Fixed height for standardization */
  width: 100%; /* Ensures the image takes full width of the card */
  object-fit: cover; /* Ensures the image covers the area without distortion */
  border-radius: 5px 5px 0 0; /* Optional: Adjusts the border-radius to match your design */
}

/* Responsive design adjustments for PlaceCard */
@media (max-width: 768px) {
  .place-card {
    width: calc(50% - 20px); /* Two cards per row on tablets */
  }
}

@media (max-width: 480px) {
  .place-card {
    width: calc(100% - 20px); /* Full width on mobile devices */
  }
}

.place-card h2 {
  margin: 10px 0;
}

.place-card p {
  margin: 5px 0;
}

.place-card img.map-icon {
  width: 20px;
  cursor: pointer;
}


.property-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
}

.property-image-container {
  position: relative;
}

.property-image-container img {
  width: 100%; /* Fixed width */
  height: 100%; /* Fixed height */
  object-fit: cover;
}

.property-type {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(247, 84, 92);
  color: #fff;
  padding: 5px 10px;
  font-size: 0.9em;
  border-radius: 5px 0px 5px 0px;
}

.property-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  background-color: #fff;
}

.property-info h3 {
  margin-top: 0;
  color: #333;
  font-size: 1.2em; /* Larger font size for visibility */
}

.property-location {
  color: #666;
  font-size: 0.9em;
}

.property-price {
  color: #333;
  font-weight: bold;
  margin-top: 10px;
}

.property-status {
  color: #fff;
  font-size: 0.9em;
  border-radius: 5px;
  margin-top: 0px;
}

.property-rating {
  background-color: rgb(247, 84, 92);
  color: #fff;
  padding: 3px 7px;
  font-size: 0.8em;
  display: inline-block;
  border-radius: 5px;
  float: none;
  text-align: right;
}

.rating-reviews-price {
  display: flex;
  align-items: center;
  font-size: 0.9em;
}

.rating-reviews-price .rating,
.rating-reviews-price .reviews,
.rating-reviews-price .price-level {
  margin-right: 5px; /* Space between elements */
}

.rating-reviews-price .separator {
  margin: 0 5px;
}

.filter-bar {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
  background-color: #f8f8f8;
  scrollbar-width: thin;
}

.filter-item {
  display: inline-block;
  background-color: #fff;
  padding: 8px 16px;
  margin-right: 10px;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  font-size: 0.9em;
  cursor: pointer;
}

.filter-item.active {
  background-color: rgb(247, 84, 92); /* Updated to specified active background color */
  border: none; /* Remove border color */
  color: white; /* Updated to white text */
}

.filter-item:last-child {
  margin-right: 0;
}

/* Style adjustments for the scrollbar */
.filter-bar::-webkit-scrollbar {
  height: 4px;
}

.filter-bar::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.type-pill {
  display: inline-block;
  margin: 2px;
  padding: 5px 10px;
  background-color: transparent; /* Changed to transparent background */
  border: 1px solid black; /* Kept black border */
  border-radius: 15px; /* Rounded corners for pill shape */
  font-size: 0.75rem; /* Smaller font size */
  color: black; /* Kept black text color */
  cursor: pointer; /* Indicates the element is clickable */
}

.type-pill.active {
  background-color: rgb(247, 84, 92); /* Updated to specified active background color */
  border: none; /* Remove border color */
  color: white; /* Updated to white text */
}

.icon-spacing {
  margin: 0 8px; /* This adds horizontal spacing. Adjust the value as needed. */
}

.types-icon {
  width: 20px;
  cursor: pointer;
  color: rgb(247, 84, 92);
}

/* App.css */

.view-toggle {
  border: 1px solid #ccc; /* Border around the toggle */
  display: inline-block; /* Make the toggle group inline */
  border-radius: 20px; /* Rounded corners for the toggle */
  overflow: hidden; /* Ensures children don't overflow the rounded corners */
}

.view-toggle button {
  padding: 10px 20px;
  border: none; /* Remove individual button borders */
  background-color: #f8f8f8;
  cursor: pointer;
  outline: none; /* Remove focus outline */
  font-size: 1em; /* Adjust font size as needed */
}

.view-toggle button.active {
  background-color: #007bff;
  color: white;
}

.view-toggle button:first-child {
  border-right: 1px solid #ccc; /* Separator between buttons */
}

/* App.css */
.miniature-map {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 20%;
  z-index: 1000;
  cursor: pointer; /* Indicates the map is clickable */
  transition: all 0.3s ease; /* Smooth transition for expanding/shrinking */
}

.expanded-map {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.trueRatings {
  color: #000000;
  font-size: 0.7em;
  display: inline-block;
  border-radius: 5px;
  float: none;
  text-align: right;
}

.star {
  color: gold; /* or any color you prefer */
  margin-right: 2px; /* adjust spacing between stars */
}

.dollarSign {
  color: black; /* or any color you prefer */
  margin-right: 2px; /* adjust spacing between stars */
}

.price-level-dropdown {
  position: absolute;
  z-index: 10;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  top: 100%; 
  left: 0;   
  transform: translateY(5px);  
}

.price-level-option {
  display: flex;
  align-items: center;
  margin-bottom: 5px; /* Adjust spacing as needed */
}

.price-level-option:last-child {
  margin-bottom: 0;
}

.price-level-option input[type="checkbox"] {
  margin-right: 5px; /* Adjust spacing as needed */
}

.price-level-option label {
  display: flex;
  align-items: center;
}

/* App.css */
.map-container {
  height: 33vh;
  width: 100%;
  margin-bottom: 10px;
}

/* App.css */
.filter-modal-box {
  position: 'absolute';
  top: '50%';
  left: '50%';
  transform: 'translate(-50%, -50%)';
  width: 400px;
  background-color: 'background.paper';
  border: '2px solid #000';
  box-shadow: 24;
  padding: 16px;
}

/* App.css */
.filter-button {
  padding: 5px 10px;
  border: 1px solid #ccc;
  font-size: 0.9em;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent; /* Default background */
  color: black; /* Default text color */
  margin-right: 5px;
  margin-left: 5px;
  flex: 1 1 auto; /* Flex grow, shrink, and basis set to auto */
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis to overflow text */
  text-align: left; /* Ensure text is left-aligned */
  position: relative; /* Position relative for pseudo-element */
}

.filter-button.active {
  background-color: rgb(247, 84, 92); /* Active background color */
  color: white; /* Active text color */
  border: none; /* No border when active */
  position: relative; /* Position relative for pseudo-element */
}

/* Styles for Movie Finder Container */
.movie-finder-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

/* Styles for the search section */
.search-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search-section input[type="text"],
.search-section input[type="date"] {
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 4px;
  width: calc(25% - 10px);
  margin-right: 10px;
}

.search-section button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-section button:hover {
  background-color: #0056b3;
}

/* Styles for movie list and cards */
.movie-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.movie-card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.movie-poster {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.movie-details {
  padding: 15px;
  line-height: 1.5;
}

.movie-details h2 {
  margin-top: 0;
  color: #333;
}

.movie-details p {
  margin: 5px 0;
  color: #666;
}

.container-preference {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.preferences {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.preference {
  margin: 10px 0;
}

.preference h3 {
  margin-bottom: 10px;
}

.preference button {
  margin: 5px;
  padding: 10px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
}

@media (min-width: 768px) {
  .preferences {
    flex-direction: row;
    justify-content: space-around;
  }
}